<script setup lang="ts">
import { computed } from 'vue';
import { Primitive } from 'radix-vue';
import { accordionVariants, cn } from '@soybean-ui/variants';
import type { AccordionContentBodyProps } from './types';

defineOptions({
  name: 'SAccordionContentBody'
});

const { class: cls } = defineProps<AccordionContentBodyProps>();

const { contentBody } = accordionVariants();

const mergedCls = computed(() => cn(contentBody(), cls));
</script>

<template>
  <Primitive as="div" :class="mergedCls">
    <slot />
  </Primitive>
</template>

<style scoped></style>
