<script setup lang="ts">
import { computed } from 'vue';
import { Primitive } from 'radix-vue';
import { cardVariants, cn } from '@soybean-ui/variants';
import type { CardTitleProps } from './types';

defineOptions({
  name: 'SCardTitle'
});

const { class: cls, size } = defineProps<CardTitleProps>();

const mergedCls = computed(() => {
  const { title } = cardVariants({ size });

  return cn(title(), cls);
});
</script>

<template>
  <Primitive as="div" :class="mergedCls">
    <slot />
  </Primitive>
</template>

<style scoped></style>
