<script setup lang="ts">
import { AlertDialogCancel, useForwardProps } from 'radix-vue';
import SButton from '../button/button.vue';
import type { ButtonProps } from '../button/types';

defineOptions({
  name: 'SAlertDialogCancel'
});

const { variant = 'plain', ...delegatedProps } = defineProps<ButtonProps>();

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <AlertDialogCancel as-child>
    <SButton v-bind="forwardedProps" :variant>
      <slot>Cancel</slot>
    </SButton>
  </AlertDialogCancel>
</template>

<style scoped></style>
