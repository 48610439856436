<script setup lang="ts">
import { computed } from 'vue';
import { RadioGroupRoot, useForwardPropsEmits } from 'radix-vue';
import type { RadioGroupRootEmits } from 'radix-vue';
import { cn, radioVariants } from '@soybean-ui/variants';
import type { RadioGroupProps } from './types';
import SRadio from './radio.vue';

defineOptions({
  name: 'SCheckboxGroup'
});

const { class: cls, color, size, items, orientation, ...delegatedProps } = defineProps<RadioGroupProps>();

const emit = defineEmits<RadioGroupRootEmits>();

const forwarded = useForwardPropsEmits(delegatedProps, emit);

const mergedCls = computed(() => {
  const { group } = radioVariants({ orientation });

  return cn(group(), cls);
});
</script>

<template>
  <RadioGroupRoot v-bind="forwarded" :class="mergedCls" :orientation>
    <slot>
      <SRadio v-for="item in items" :key="item.value" v-bind="item" :color="color" :size="size" />
    </slot>
  </RadioGroupRoot>
</template>

<style scoped></style>
