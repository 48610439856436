<script setup lang="ts">
import { computed } from 'vue';
import { TabsContent } from 'radix-vue';
import { cn, tabsVariants } from '@soybean-ui/variants';
import type { TabsContentProps } from './types';

defineOptions({
  name: 'STabsContent'
});

const { class: cls, orientation, value, forceMount } = defineProps<TabsContentProps>();

const mergedCls = computed(() => {
  const { content } = tabsVariants({ orientation });

  return cn(content(), cls);
});
</script>

<template>
  <TabsContent :class="mergedCls" :value :force-mount>
    <slot />
  </TabsContent>
</template>

<style scoped></style>
