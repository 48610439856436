<script setup lang="ts">
import { computed } from 'vue';
import { SelectScrollDownButton } from 'radix-vue';
import { ChevronDown } from 'lucide-vue-next';
import { cn, selectVariants } from '@soybean-ui/variants';
import type { SelectScrollDownButtonProps } from './types';

defineOptions({
  name: 'SSelectScrollDownButton'
});

const { class: cls, size } = defineProps<SelectScrollDownButtonProps>();

const mergedCls = computed(() => {
  const { scrollDownButton } = selectVariants({ size });

  return cn(scrollDownButton(), cls);
});
</script>

<template>
  <SelectScrollDownButton :class="mergedCls">
    <slot>
      <ChevronDown />
    </slot>
  </SelectScrollDownButton>
</template>

<style scoped></style>
